class ApiResponse {
  constructor({
    success = false,
    code = 0,
    message = "",
    error = null,
    data = null,
    extras = null,
  } = {}) {
    this.success = success;
    this.code = code;
    this.message = message;
    this.error = error;
    this.data = data;
    this.extras = extras;
  }

  static fromJson(json) {
    return new ApiResponse({
      success: json.success || false,
      code: json.code || 0,
      message: json.message || json.errors?.error_text || "",
      error: json.errors || null,
      data: json.data || null,
      extras: json.extras || null,
    });
  }

  toJson() {
    return {
      success: this.success,
      code: this.code,
      message: this.message,
      error: this.error,
      data: this.data,
      extras: this.extras,
    };
  }
}

export default ApiResponse;
