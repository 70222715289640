class User {
  constructor({
    pro_type = "",
    pro_remainder = "",
    adminPanel = "",
    API_notification_settings = "",
    country_id = "",
    friend_privacy = "",
    follow_privacy = "",
    email = "",
    phone_number = "",
    username = "",
    points = "",
    url = "",
    user_id = "",
    confirm_followers = "",
    can_follow = 0,
    wallet = "",
    first_name = "",
    working = "",
    address = "",
    balance = "",
    gender = "",
    last_name = "",
    birthday = "",
    following_count = 0,
    post_count = 0,
    is_following = 0,
    avatar = "",
    followers_count = 0,
    verified = "",
    cover = "",
    name = "",
    about = "",
    school = "",
    working_link = "",
    website = "",
    message_privacy = "",
    is_following_me = 0,
    google = "",
    vk = "",
    twitter = "",
    linkedin = "",
    instgram = "",
    youtube = "",
    facebook = "",
    deriv_acc_id = null,
  }) {
    this.pro_type = pro_type;
    this.pro_remainder = pro_remainder;
    this.adminPanel = adminPanel;
    this.API_notification_settings = API_notification_settings;
    this.country_id = country_id;
    this.friend_privacy = friend_privacy;
    this.follow_privacy = follow_privacy;
    this.email = email;
    this.phone_number = phone_number;
    this.username = username;
    this.points = points;
    this.url = url;
    this.user_id = user_id;
    this.confirm_followers = confirm_followers;
    this.can_follow = can_follow;
    this.wallet = wallet;
    this.first_name = first_name;
    this.working = working;
    this.address = address;
    this.balance = balance;
    this.gender = gender;
    this.last_name = last_name;
    this.birthday = birthday;
    this.following_count = following_count;
    this.post_count = post_count;
    this.is_following = is_following;
    this.avatar = avatar;
    this.followers_count = followers_count;
    this.verified = verified;
    this.cover = cover;
    this.name = name;
    this.about = about;
    this.school = school;
    this.working_link = working_link;
    this.website = website;
    this.message_privacy = message_privacy;
    this.is_following_me = is_following_me;
    this.google = google;
    this.vk = vk;
    this.twitter = twitter;
    this.linkedin = linkedin;
    this.instgram = instgram;
    this.youtube = youtube;
    this.facebook = facebook;
    this.deriv_acc_id = deriv_acc_id;
  }

  static fromJson(json) {
    return new User({
      pro_type: json.pro_type || "",
      pro_remainder: json.pro_remainder || "",
      adminPanel: json.admin || "",
      API_notification_settings: json.API_notification_settings || "",
      country_id: json.country_id || "",
      friend_privacy: json.friend_privacy || "",
      follow_privacy: json.follow_privacy || "",
      email: json.email || "",
      phone_number: json.phone_number || "",
      username: json.username || "",
      points: json.points || "",
      url: json.url || "",
      user_id: json.user_id || "",
      confirm_followers: json.confirm_followers || "",
      can_follow: json.can_follow === "1" ? 1 : 0,
      wallet: json.wallet || "",
      first_name: json.first_name || "",
      working: json.working || "",
      address: json.address || "",
      balance: json.balance || "",
      gender: json.gender || "",
      last_name: json.last_name || "",
      birthday: json.birthday || "",
      following_count: json.details?.following_count || 0,
      post_count: json.details?.post_count || 0,
      is_following: json.is_following === "1" ? 1 : 0,
      avatar: json.avatar || "",
      followers_count: json.details?.followers_count || 0,
      verified: json.verified || "",
      cover: json.cover || "",
      name: json.name || "",
      about: json.about || "",
      school: json.school || "",
      working_link: json.working_link || "",
      website: json.website || "",
      message_privacy: json.message_privacy || "",
      is_following_me: json.is_following_me === "1" ? 1 : 0,
      google: json.google || "",
      vk: json.vk || "",
      twitter: json.twitter || "",
      linkedin: json.linkedin || "",
      instgram: json.instgram || "",
      youtube: json.youtube || "",
      facebook: json.facebook || "",
      deriv_acc_id: json.deriv_acc_id || "",
    });
  }

  toJson() {
    return {
      pro_type: this.pro_type,
      pro_remainder: this.pro_remainder,
      admin: this.adminPanel,
      API_notification_settings: this.API_notification_settings,
      country_id: this.country_id,
      friend_privacy: this.friend_privacy,
      follow_privacy: this.follow_privacy,
      email: this.email,
      phone_number: this.phone_number,
      username: this.username,
      points: this.points,
      url: this.url,
      user_id: this.user_id,
      confirm_followers: this.confirm_followers,
      can_follow: this.can_follow,
      wallet: this.wallet,
      first_name: this.first_name,
      working: this.working,
      address: this.address,
      balance: this.balance,
      gender: this.gender,
      last_name: this.last_name,
      birthday: this.birthday,
      following_count: this.following_count,
      post_count: this.post_count,
      is_following: this.is_following,
      avatar: this.avatar,
      followers_count: this.followers_count,
      verified: this.verified,
      cover: this.cover,
      name: this.name,
      about: this.about,
      school: this.school,
      working_link: this.working_link,
      website: this.website,
      message_privacy: this.message_privacy,
      is_following_me: this.is_following_me,
      google: this.google,
      vk: this.vk,
      twitter: this.twitter,
      linkedin: this.linkedin,
      instgram: this.instgram,
      youtube: this.youtube,
      facebook: this.facebook,
      deriv_acc_id: this.deriv_acc_id,
    };
  }
}

export default User;
