import ApiRepository from "../api/api_repository";
import { apiUrl } from "../api/config";

// Initialize the ApiRepository with the token
const apiRepository = new ApiRepository();
window.apiRepository = apiRepository; // Add instance to global window object


function handleLogin() {
  // Redirect to the API URL
  window.location.href = apiUrl; // Or use window.location.assign(apiUrl);
}

async function handleProfile(){
    try {
                    const user = window.apiRepository ? await window.apiRepository.getCurrentUser() : null;
                    const username = user && typeof user.username === 'string' ? user.username : '';
                    window.location.href = `${apiUrl}/${username}`;
                } catch (error) {
                    console.error('Error fetching current user:', error);
                    window.location.href = apiUrl;
                }
}

const loginHandler = () => {
try {
  
  // Attach event listener to the button
  document.getElementById("d-login-btn")?.addEventListener("click", handleLogin);
} catch (error) {
  
}
try {
  // Attach event listener to the button
  document.getElementById("d-profile-btn")?.addEventListener("click", handleProfile);
} catch (error) {}
  console.log("login Handler loaded");

  const loginForm = document.getElementById("login-form");
  const logoutBtn = document.getElementById("d-logout-btn");

  logoutBtn &&
    logoutBtn.addEventListener("click", async () => {
      console.log("To log out");
      try {
        if (await window.apiRepository.logOut()) {
          alert("Logout successful!");
          window.location.href = apiUrl + "/logout/"; // Redirect to the login page
          return;
        }
      } catch (error) {
        console.error(error);
      }
      //  window.location.reload();
      alert("Something went wrong, reload the page and try again.");
    });

  loginForm &&
    loginForm.addEventListener("submit", async (event) => {
      event.preventDefault(); // Prevent the default form submission

      const username = document.getElementById("username").value;
      const password = document.getElementById("password").value;

      if (!username || !password) {
        alert("Please enter both username and password.");
        return;
      }

      try {
        const response = await window.apiRepository.loginUser(
          username,
          password
        );
        console.log(response);
        if (response && response.user_id) {
          alert("Login successful!");

          // Redirect to another page or perform other actions as needed
          window.location.href = "/"; // Adjust the URL as needed
        } else {
          alert("Login failed, check your username or password and try again!");
        }
      } catch (error) {
        console.error("Login failed:", error);
        alert(
          "Login failed: " + (error.message || "An unexpected error occurred.")
        );
      }
    });
};

export default loginHandler;
