import ApiClient from "./api_client";
import {
  ApiException,
  EmailCannotBeEmptyLoginException,
  PasswordCannotBeEmptyLoginException,
} from "./models/ApiException";
import CryptoJS from "crypto-js";

class ApiRepository {
  static instance = null;
  static user = null;

  constructor(token) {
    if (ApiRepository.instance) {
      return ApiRepository.instance;
    }

    // Initialize the ApiClient with a token if necessary
    const apiClient = new ApiClient({ token: this.retrieveApiKey() });

    this.apiClient = apiClient;

    ApiRepository.instance = this;
  }

  async getCurrentUser(userId = null) {
    if (!ApiRepository.user) {
      const user = await this.fetchUser(userId);
      if (!!user.user_id) {
        ApiRepository.user = user;
      }
    }

    return ApiRepository.user;
  }

  async loginByToken({ userId, token }) {
    if (!userId) {
      throw new EmailCannotBeEmptyLoginException();
    }
    if (!token) {
      throw new PasswordCannotBeEmptyLoginException();
    }

    try {
      // Store the token if the login is successful
      if (token) {
        ApiClient.apiKey = token;

        const user = await this.getCurrentUser(userId);

        console.log(user);
        if (user.user_id) {
          this.storeApiKey(token); // Store token in local storage
          this.store("userId", userId);
          // this.store("isMember", isMember);
          console.log("logged in by token");
          return true;
        }

        throw new Error("Invalid token");
      }

      throw new Error("Something went wrong.");
    } catch (error) {
      if (error instanceof ApiException) {
        throw error;
      } else {
        console.error("Unexpected error during login:", error);
        throw new Error("An unexpected error occurred during login.");
      }
    }
  }

  async loginUser(username, password) {
    if (!username) {
      throw new EmailCannotBeEmptyLoginException();
    }
    if (!password) {
      throw new PasswordCannotBeEmptyLoginException();
    }

    try {
      const {
        user_id: userId,
        access_token: accessToken,
        membership: isMember,
      } = await this.apiClient.userLogin({
        username,
        password,
      });

      // Store the token if the login is successful
      if (accessToken) {
        ApiClient.apiKey = accessToken;
        this.storeApiKey(accessToken); // Store token in local storage
        this.store("userId", userId);
        this.store("isMember", isMember);

        return await this.getCurrentUser();
      }
      throw new Error("Something went wrong.");
    } catch (error) {
      if (error instanceof ApiException) {
        throw error;
      } else {
        console.error("Unexpected error during login:", error);
        throw new Error("An unexpected error occurred during login.");
      }
    }
  }

  async isLoggedIn(shouldBePro = false) {
    const token = this.retrieveApiKey();
    if (!token) {
      return false;
    }

    // Optionally, verify if the token is valid by making an API call to fetch users info
    return this.getCurrentUser()
      .then(function (userModel) {
        if (!!shouldBePro) {
          return !!userModel && userModel.pro_type > 0;
        }
        return !!userModel;
      })
      .catch(() => false);
  }

  async isLoggedInProCheck() {
    const token = this.retrieveApiKey();
    if (!token) {
      return false; // User is not logged in at all
    }
  
    try {
      // Fetch the current user details
      const userModel = await this.getCurrentUser();
  
      if (!userModel) {
        return false; // Unable to retrieve user details
      }
  
      // Check if the user is a Pro Member
      if (userModel.pro_type > 0) {
        // Fetch the remaining time as a Pro Member
        const proRemainderTimestamp = userModel.pro_remainder; // Assuming this is fetched from `userModel`
  
        if (!proRemainderTimestamp) {
          console.log("No Pro remainder timestamp available.");
          return !!userModel; // Keep the user logged in as an ordinary member
        }
  
        // Convert the timestamp to a Date object and compare it with the current time
        const currentTime = new Date();
        const proRemainderTime = new Date(proRemainderTimestamp);
  
        if (proRemainderTime > currentTime) {
          // Membership is still active
          console.log("User is a Pro Member with active membership.");
          return true; // Remain logged in as a Pro Member
        } else {
          // Membership has expired
          console.log("Pro membership has expired. Switching to ordinary member.");
          userModel.pro_type = 0; // Downgrade to ordinary member
          return true; // Keep the user logged in as an ordinary member
        }
      } else {
        // User is not a Pro Member
        console.log("User is logged in as an ordinary member.");
        return true;
      }
    } catch (error) {
      console.error("Error checking Pro membership status:", error);
      return false; // Return false in case of an error
    }
  }
  

  async setDerivAccId(derivId, userId) {
    try {
      console.log("setDerivAccId: " + userId);
      userId ??= this.retrieve("userId");
      console.log(userId);
      if (!userId) {
        return false;
      }
      console.log("setDerivAccId: " + derivId);
      const user = await this.apiClient.setDerivId(userId, derivId);
      console.debug(user);
      if (!!user.user_id) {
        ApiRepository.user = user;
      }
      return user;
    } catch (error) {
      console.error("Error setting deriv id:", error);
      return false;
    }
  }

  async isMyDerivId(derivId) {
    const token = this.retrieveApiKey();
    if (!token) return false;

    console.log("isMyDerivId " + derivId);

    try {
      const userModel = await this.getCurrentUser();
      console.log("derivTokenHandler: auth - " + !!userModel);

      if (!userModel) return false;

      if (!!userModel.deriv_acc_id) {
        if (userModel.deriv_acc_id === derivId) {
          // console.log(
          //   "derivTokenHandler: confirmed 1 " + userModel.deriv_acc_id
          // );
          return true;
        }
      } else {
        // console.log(
        //   "derivTokenHandler: confirming 2 " + userModel.deriv_acc_id
        // );
        try {
          const updatedUser = await this.setDerivAccId(
            derivId,
            userModel.user_id
          );
          // console.log(
          //   "derivTokenHandler: confirmed 2 " + updatedUser.deriv_acc_id
          // );
          return updatedUser?.deriv_acc_id === derivId;
        } catch {
          return false;
        }
      }

      return false;
    } catch {
      return false;
    }
  }
  async isProMember() {
    const token = this.retrieveApiKey();
    if (!token) {
      return false;
    }

    // Optionally, verify if the token is valid by making an API call to fetch user info
    return this.getCurrentUser()
      .then((userModel) => !!userModel && userModel.pro_type > 0)
      .catch(() => false);
  }

  async logOut() {
    try {
      this.removeApiKey(); // Remove token in local storage
      this.remove("userId");
      this.remove("isMember");
    } catch (error) {
      console.error(error);
    }
    return !(await this.isLoggedIn());
  }

  async fetchUser(userId = null) {
    try {
      userId ??= this.retrieve("userId");
      console.log(userId);
      if (!userId) {
        return null;
      }

      const user = await this.apiClient.getUser(userId);
      console.debug(user);
      return user;
    } catch (error) {
      console.error("Error fetching user model:", error);
      return null;
    }
  }

  store(key, value, encrypt = false) {
    try {
      let valueToStore = value;
      if (encrypt) {
        // Encrypt the API key before storing
        const encrypted = CryptoJS.AES.encrypt(value, secretKey).toString();
        valueToStore = encrypted;
      }
      localStorage.setItem(key, valueToStore);
    } catch (error) {
      console.error(`Error storing ${key}:`, error);
    }
  }

  remove(key) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing ${key}:`, error);
    }
  }
  retrieve(key, decrypt = false) {
    try {
      let value = localStorage.getItem(key) || null;
      if (decrypt && value) {
        // Decrypt the API key before returning
        const bytes = CryptoJS.AES.decrypt(value, secretKey);
        value = bytes.toString(CryptoJS.enc.Utf8);
      }
      return value == undefined || value == "undefined" ? null : value;
    } catch (error) {
      console.error(`Error retrieving ${key}:`, error);
      return null;
    }
  }
  removeApiKey() {
    this.remove("binotoken");
  }
  storeApiKey(apiKey, encrypt = true) {
    return this.store("binotoken", apiKey, encrypt);
  }

  retrieveApiKey(decrypt = true) {
    return this.retrieve("binotoken", decrypt);
  }
}

const secretKey =
  'in£Its%App&ointed= Time-The(L@rd)Will Do7Things-InThe!"£$ N%tion-MostWouldN"£$% .t%SeeIt$_)(*&^%$)=';

export default ApiRepository;
